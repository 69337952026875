<div class="w3-container" style="width: 100%; margin-top: 10px">
            
<!--   <div class="w3-container w3-blue w3-margin-bottom">
        <h2>Profile</h2>
    </div>-->
    
    <div class="row">
      <div class="col-11">
          <h3>User Profile</h3>
      </div>
      <div class="col-1">
        <i class='fas fa-window-close' style='font-size:24px;color:gray; float: right;' (click)="close()"></i>
      </div>
    </div>
  

    

    <form #profileForm="ngForm">
        <div class="w3-container w3-row-padding">
            <div class="w3-col m6 l6">
                <label class="w3-margin-top"><b>First Name</b></label>
                <input class="w3-input w3-border w3-light-grey" id="_firstname" type="text" readonly value="{{dataService.userData.firstname}}" >
            </div>
            <div class="w3-col m6 l6">
                <label class="w3-margin-top"><b>Last Name</b></label>
                <input class="w3-input w3-border w3-light-grey" id="_lastname" type="text" readonly value="{{dataService.userData.lastname}}">
            </div>
        </div>

        <div class="w3-container w3-row-padding w3-margin-top">

          <div class="w3-col m6 l6">
              <label class="w3-margin-top"><b>Department</b></label>
              <br>

              <mat-form-field appearance="fill">
                  <!--<mat-label>Department</mat-label>-->
                  <mat-select [(value)]="dataService.userData.departmentid" (selectionChange)="changeDepartment()">
                    <mat-option *ngFor="let dept of dataService.departments" [value]="dept.id">
                      {{dept.name}}
                    </mat-option>
                  </mat-select>
              </mat-form-field>
              

          </div>

          <div class="w3-col m6 l6">
              <label class="w3-margin-top"><b>Specialty</b></label>
              <br>
              <mat-form-field appearance="fill">
                  <mat-select [(value)]="dataService.userData.specialtyid">
                    <mat-option *ngFor="let spec of dataService.specialties" [value]="spec.id">
                      {{spec.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
          </div>                    


            <!--<div class="w3-col m6 l6 w3-margin-top">
                <button class="w3-margin-top w3-margin-top btn btn-info" (click)="chooseManager()">Lookup</button>
            </div>-->
        </div> 
                             

        <div class="w3-container w3-row-padding w3-margin-top">
            <div class="w3-col m4 l4">
                <label class="w3-margin-top"><b>PTO Hours Used</b></label>
                <input class="w3-input w3-border" name="pto" id="pto" [(ngModel)]="dataService.userData.pto" type="number" #pto="ngModel">
            </div>
            <div class="w3-col m4 l4">
                <label class="w3-margin-top"><b>PTO Hours Remaining</b></label>
                <input class="w3-input w3-border" name="_ptoremaining" id="_ptoremaining" [(ngModel)]="dataService.userData.ptoremaining" type="number" #ptoremaining="ngModel">

            </div>   

        </div>         
                              
        <div class="w3-container w3-row-padding w3-margin-top">
          <button class="w3-btn w3-blue-grey w3-margin-top w3-margin-right" (click)="updateProfile()">Save</button>
          <button class="w3-btn w3-grey w3-margin-top" (click)="close()">Cancel</button>     
        </div>
        
        <!--<div class="w3-container w3-row-padding w3-margin-top w3-lightgray">
          {{dataService.userData | json}}
        </div>-->
      
        <div class="w3-container w3-row-padding w3-margin-top w3-red" *ngIf="errorMsg!=''">
          {{errorMsg}}
        </div>

    </form>
 
</div>





