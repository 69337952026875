<!--------------------------- EDIT REQUEST ---------------------------->
<div *ngIf="!adding" >
  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-11">
          <h3>Holiday</h3>
      </div>
      <div class="col-1">
        <i class='fas fa-window-close' style='font-size:24px;color:gray; float: right;' (click)="close()"></i>
      </div>
    </div>
    <div class="row">   
          
    </div>  
    <div class="row spacer-t-10">
      <label>Description</label>
    </div>
    <div class="row">          
      <mat-form-field appearance="fill">
        <textarea matInput [(ngModel)]="dataService.userData.notes"></textarea>
      </mat-form-field>
    </div>
 

    <div class="row">
      <label>Date:</label>
    </div>
    <div class="row">          
      <div class="col-12">
        <mat-form-field appearance="fill" class="example-form-field" style="display: contents" >
          <mat-date-range-input [rangePicker]="rangePicker" (change)="changeDate()" [dateFilter]="myFilter">
            <input matStartDate [(ngModel)]="dataService.userData.StartTime" placeholder="Start date">
            <input matEndDate [(ngModel)]="dataService.userData.EndTime" placeholder="End date">
          </mat-date-range-input>
          <!--<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>-->
          <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #rangePicker  >
            <mat-date-range-picker-actions>
              <button mat-button matDateRangePickerCancel>Cancel</button>
              <button mat-raised-button color="primary" matDateRangePickerApply (click)="changeDate()">Apply</button>
            </mat-date-range-picker-actions>
          </mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>


    <div class="row">          
      <mat-error *ngIf="isError">{{errorMessages}}</mat-error>
    </div>

   

                          
    <div class="w3-container w3-row-padding w3-margin-top">
      <button class="w3-btn w3-blue-grey w3-margin-top w3-margin-right" (click)="save()">Save</button>
      <button class="w3-btn w3-grey w3-margin-top" (click)="close()">Cancel</button>     
    </div>
  </mat-dialog-content>
</div>


<!--------------------------- NEW REQUEST ---------------------------->


<div *ngIf="adding" >
      <mat-dialog-content class="mat-typography">
        <div class="row">
          <div class="col-11">
              <h3>Add Holiday</h3>
          </div>
          <div class="col-1">
            <i class='fas fa-window-close' style='font-size:24px;color:gray; float: right;' (click)="close()"></i>
          </div>
        </div>
  

        <div class="row">   
          
        </div>  
        <div class="row spacer-t-10">
          <label>Description</label>
        </div>
        <div class="row">          
          <mat-form-field appearance="fill">
            <textarea matInput [(ngModel)]="dataService.request.notes"></textarea>
          </mat-form-field>
        </div>
     

        <div class="row">
          <label>Date range</label>
        </div>
        <div class="row">          
          <div class="col-12">
            <mat-form-field appearance="fill" class="example-form-field" style="display: contents" >
              <mat-date-range-input [rangePicker]="rangePicker" (change)="changeDate()" [dateFilter]="myFilter">
                <input matStartDate [(ngModel)]="dataService.request.startdate" placeholder="Start date">
                <input matEndDate [(ngModel)]="dataService.request.enddate" placeholder="End date">
              </mat-date-range-input>
              <!--<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>-->
              <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #rangePicker  >
                <mat-date-range-picker-actions>
                  <button mat-button matDateRangePickerCancel>Cancel</button>
                  <button mat-raised-button color="primary" matDateRangePickerApply (click)="changeDate()">Apply</button>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>


        <div class="row">          
          <mat-error *ngIf="isError">{{errorMessages}}</mat-error>
        </div>

       

                              
        <div class="w3-container w3-row-padding w3-margin-top">
          <button class="w3-btn w3-blue-grey w3-margin-top w3-margin-right" (click)="submit()">Submit</button>
          <button class="w3-btn w3-grey w3-margin-top" (click)="close()">Cancel</button>     
        </div>

      </mat-dialog-content>

</div>



