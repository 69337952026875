import { Component, OnInit , Inject, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
//import { extend } from '@syncfusion/ej2-base';
//import { extend, Internationalization, closest, isNullOrUndefined } from '@syncfusion/ej2-base';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from '../../_services/config.service';
import { AccountService } from '@app/_services';
import { DataService, PTOService } from '@app/_services';
import { PTORequestComponent } from '@app/_components/ptorequest/ptorequest.component';
import { HolidayComponent } from '@app/_components/holiday/holiday.component';
import { NamelistComponent } from '@app/_components/namelist/namelist.component';
import { ProfileComponent } from '@app/_components/profile/profile.component';
import { takeUntil, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';

import { PopupOpenEventArgs, TimeScaleModel, View, EventSettingsModel, DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService, EventRenderedArgs, ScheduleComponent, ActionEventArgs, EJ2Instance } from '@syncfusion/ej2-angular-schedule';
import { DataManager, ODataV4Adaptor, Query } from '@syncfusion/ej2-data';
//import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DateTimePicker } from '@syncfusion/ej2-calendars';
import { Dialog } from '@syncfusion/ej2-angular-popups';

import { DialogEditEventArgs, SaveEventArgs, EditSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { DataUtil } from '@syncfusion/ej2-data';
import { FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

const baseUrl = `${environment.apiUrl}`;
const directoryUrl = `${environment.directoryUrl}`;

@Component({ 
    templateUrl: 'dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService],
    encapsulation: ViewEncapsulation.None

})


export class DashboardComponent implements OnInit {
    public historyType = 'requests';
    public calendarRequestor = 0;
    public userData: object;
    @ViewChild('userForm') public userForm: FormGroup;
    public editSettings: EditSettingsModel;
    public toolbar: ToolbarItems[];
    @ViewChild('scheduleObj')
    public scheduleObj: ScheduleComponent;
    account = this.accountService.accountValue;
    isManager = 0;
    isSA = 0;
    public isEditing = false;
    alertTab = "red";
    public header;
    public currentEvent;
    
    public showQuickInfo = false;
    public scheduleView: View = 'Week';
    public monthEventTemplate = '<div class="e-subject">${Subject} (${status})</div>';
    public agendaEventTemplate = '`<div class="e-subject">${Subject} (${status})<br>${StartTime.toLocaleTimeString()} - ${EndTime.toLocaleTimeString()}</div>';
    public timeScale: TimeScaleModel = { interval: 60, slotCount: 4 };
    //public selectedDate: Date = new Date(2022, 0, 10);
    //public eventSettings: EventSettingsModel = { dataSource: extend([], scheduleData, null, true) as Record<string, any>[] };


    public readonly: boolean = false;
    public selectedDate: Date = new Date();
    private dataManager: DataManager = new DataManager({
      //  url: 'https://ej2services.syncfusion.com/production/web-services/api/Schedule',
        url: `${baseUrl}/requests`,
        adaptor: new ODataV4Adaptor,
        crossDomain: true
    });
    public eventSettings: EventSettingsModel = { dataSource: this.dataManager };
    public unapprovedRequests: Object[];
    public requestHistory: Object[];
    public ledgerHistory: Object[];
    public users: Object[];
    public holidays: Object[];
    public pageSettings: Object;
    public currentTab = 0;
    public currentTabName = "Home";
    public years = [2023, 2024];
   
    constructor(
            private accountService: AccountService,
            public dataService: DataService,
            public ptoService: PTOService,
            private configService: ConfigService,
            private cookieService: CookieService,
            private route: ActivatedRoute,
            private router: Router,
            public dialog: MatDialog
        ) {
            this.configService.getConfig().pipe(
                map(config => {
    
                    //this.dataService.year = this.cookieService.get('year');
                    this.dataService.configYear = config.currentyear;
                    //if (!this.dataService.year) {
                        this.dataService.year = config.currentyear;
                      //  this.cookieService.set('year', this.dataService.year);
                    //}
    
                    this.loadData();

                }),
                ).subscribe();
    }

    //public data: string[] = ['Badminton', 'Basketball', 'Cricket', 'Football', 'Golf', 'Hockey', 'Rugby', 'Snooker','Tennis'];

 
    ngOnInit(): void {

        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
        this.toolbar = ['Edit'];
        this.pageSettings = { pageCount: 200 };

        this.accountService.allmyemployees(this.account.user.id).pipe(
            map(users => {
                this.dataService.employees = users;
            })
        ).subscribe();

        // get the departments
        this.configService.getDepartments().pipe(
            map(departments => {
                    this.dataService.departments = departments;
                    debugger;

                    if (this.account.userinfo.department) {
                        this.configService.getSpecialities(this.account.userinfo.department).pipe(
                            map(specialties => {
                                    this.dataService.specialties = specialties;
                            }),
                        ).subscribe();        
                    }
            }),
        ).subscribe();

        //this.scheduleObj.scrollTo('17:00');


        this.dataService.allusers = [];
    }

    loadData() {
        this.accountService.userInfo(this.accountService.accountValue.user.id, this.dataService.year).pipe(
            map(ui => {
                    this.accountService.accountValue.userinfo = ui;
                    this.isSA = ui.superadmin;

                    this.accountService.employees(this.accountService.accountValue.user.id).pipe(
                        map(emp => {
                                this.accountService.accountValue.employees = emp;
                                this.isManager = this.account.employees.length > 0 ? 1 : 0;
                        }),
                    ).subscribe();
            
                    // is this an SA?
                    if (this.isSA) {
                        this.ptoService.getRequestsByStatus('new', this.dataService.year).pipe(
                            map(data => {
                                this.unapprovedRequests = data;
                            }),
                        ).subscribe();
                    } else {
                        this.ptoService.getRequestsByApproverStatus(this.account.user.id, 'new', this.dataService.year).pipe(
                            map(data => {
                                    this.unapprovedRequests = data;
                            }),
                        ).subscribe();
            
                    }
            }),
        ).subscribe();        
    }

    loadHistory() {

        //this.dataService.request.requestor = this.account.user.id;

        if (this.isSA) {
            this.accountService.getAllByYear(this.dataService.year).pipe(
                map(data => {
                        this.users = data;
                }),
            ).subscribe();
        } else {
            this.accountService.myemployeesbyyear(this.account.user.id, this.dataService.year).pipe(
                map(data => {
                        this.users = data;
                }),
            ).subscribe();
        }


        this.ptoService.getHistory(this.dataService.request.requestor, this.dataService.year).pipe(
            map(data => {
                    this.requestHistory = data;
            }),
        ).subscribe();

        this.ptoService.getLedger(this.dataService.request.requestor, this.dataService.year).pipe(
            map(data => {
                    this.ledgerHistory = data;
            }),
        ).subscribe();
    }


    loadHolidays() {
        this.dataService.request.requestor = this.account.user.id;

        if (this.isSA) {
            this.accountService.getAll().pipe(
                map(data => {
                        this.users = data;
                }),
            ).subscribe();
        } else {
            this.accountService.myemployees(this.account.user.id).pipe(
                map(data => {
                        this.users = data;
                }),
            ).subscribe();
        }


        this.ptoService.getHolidays(this.dataService.year).pipe(
            map(data => {
                    this.holidays = data;
            }),
        ).subscribe();

    }

    loadRequests() {
        if (this.isSA) {
            this.ptoService.getRequestsByStatus('new', this.dataService.year).pipe(
                map(data => {
                    this.unapprovedRequests = data;
                }),
            ).subscribe();
        } else {
            this.ptoService.getRequestsByApproverStatus(this.account.user.id, 'new', this.dataService.year).pipe(
                map(data => {
                        this.unapprovedRequests = data;
                }),
            ).subscribe();

        }
    }

    loadUsers() {

        this.accountService.getAllByYear(this.dataService.year).pipe(
            map(data => {
                    this.users = data;
            }),
        ).subscribe();
    }

   


    updateYear() {
        this.cookieService.set('year', this.dataService.year);
        //this.currentTab = 0;
        this.loadData();

        if (this.currentTabName === 'Users') {
            this.loadUsers();
        }

        if (this.currentTabName === 'Calendar') {
            this.loadCalendar();
        }

        if (this.currentTabName === 'History') {
            this.loadHistory();
        }

        if (this.currentTabName === 'Holidays') {
            this.loadHolidays();
        }        
    }

    onTabClick(event) {
        console.log(event);
        console.log(event.tab.textLabel);

        this.currentTab = event.index;
        this.currentTabName = event.tab.textLabel;

        this.loadRequests();
      
        if (event.tab.textLabel === 'Calendar') {
            this.calendarRequestor = 0;//this.account.user.id;
            this.loadCalendar();
        }

        if (event.tab.textLabel === 'History') {
            this.dataService.request.requestor = this.account.user.id;
            this.loadHistory()

        }

        if (event.tab.textLabel === 'Users') {
            this.loadUsers();

        }

        if (event.tab.textLabel === 'Holidays') {
            this.loadHolidays();
        }
    }   

    loadCalendar() {
        debugger;

        //if (this.isSA) {
        this.accountService.getAllByYear(this.dataService.year).pipe(
            map(data => {
                    this.users = data;
            }),
        ).subscribe();
        //} else {
          //  this.accountService.myemployeesbyyear(this.dataService.request.requestor, this.dataService.year).pipe(
            //    map(data => {
              //          this.users = data;
                //}),
            //).subscribe();
        //}
        this.scheduleObj.refresh();

        if (this.calendarRequestor === 0) {
            //if (this.isSA) {
                this.dataManager.dataSource.url = `${baseUrl}/requests/year/${this.dataService.year}`;
            //} else {
              //  this.dataManager.dataSource.url = `${baseUrl}/requests/user/${this.calendarRequestor}/year/${this.dataService.year}`;
            //}
        } else {
            this.dataManager.dataSource.url = `${baseUrl}/requests/user/${this.calendarRequestor}/year/${this.dataService.year}`;
        }
        this.scheduleObj.refresh();
    }

    changeDepartment() {
        debugger;
        this.configService.getSpecialities(this.account.userinfo.department).pipe(
            map(specialties => {
                    this.dataService.specialties = specialties;
            }),
        ).subscribe();        
    }

    /*
    changeHistory() {
        this.ptoService.getRequestsByRequestor(this.dataService.request.requestor, this.dataService.year).pipe(
            map(data => {
                    this.requestHistory = data;
            }),
        ).subscribe();
        this.ptoService.getLedger(this.dataService.request.requestor, this.dataService.year).pipe(
            map(data => {
                    this.ledgerHistory = data;
            }),
        ).subscribe();
    }
*/
    


    ngOnDestroy(): void {        

    }

    applyCategoryColor(args: EventRenderedArgs): void {
        let categoryColor: string = "green";

        debugger;

        if (args.data.status=="new") {
            categoryColor = "blue";
        }
        if (args.data.status=="denied") {
            categoryColor = "red";
        }

        if (args.data.status=="blocked") {
            categoryColor = "darkgray";
        }

        if (this.scheduleObj.currentView === 'Agenda') {
            (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
        } else {
            args.element.style.backgroundColor = categoryColor;
        }
    }

    requestPTO() {
        this.dataService.resetRequest();
        this.dataService.dialogRef = this.dialog.open(PTORequestComponent, {
            data: { isManager: this.isManager, isSA: this.isSA, adding: true },
            height: '70%',
            width: '45%',
        });
        this.dataService.dialogRef.afterClosed().subscribe(result => {
            debugger;

            if (!result) {
                this.dataService.request.requestor = 0;
            } else {
                this.account = this.accountService.accountValue;
                this.loadData();
                this.scheduleObj.refresh();
                window.location.reload();
            }

            console.log(this.dataService.request);

        });
        return false;
    }


    addHoliday() {
        this.dataService.resetRequest();
        this.dataService.dialogRef = this.dialog.open(HolidayComponent, {
            data: { isManager: this.isManager, isSA: this.isSA, adding: true },
            height: '40%',
            width: '35%',
        });
        this.dataService.dialogRef.afterClosed().subscribe(result => {
            debugger;

            this.loadHolidays();

        });
        return false;
    }

    public dateFormatter = (field: string, data1: object, column: object) => {
        var tDate = new Date(data1[field]);
        return tDate.toLocaleDateString() + " " + tDate.toLocaleTimeString(); 
    }

    onPopupOpen(args: PopupOpenEventArgs): void {
        if (args.data.status) {
            if (args.type === 'Editor') {
                args.cancel = true;
                debugger;
               
                this.openRequest(args);
            }
        } else {
            args.cancel = true;
        }
    }

   

    
    public dialogClose() {
        let dialogObj: Dialog = (document.querySelector('.e-schedule-dialog') as EJ2Instance).ej2_instances[0] as Dialog;

        dialogObj.hide();

    }

    openRequest(args) {
        debugger;

        if (args.data.status === 'blocked') {
            return false;
        } else {

            // does this person have access
            const result = this.dataService.employees.find(({ id }) => id === args.data.userid);

            this.ptoService.getRequest(args.data.Id).pipe(
                map(data => {
                        this.dataService.request = data;

                        this.dataService.dialogRef = this.dialog.open(PTORequestComponent, {
                            data: { isManager: this.isManager, isSA: this.isSA, adding: false, fullaccess: result },
                            height: '60%',
                            width: '45%',
                        });
                        this.dataService.dialogRef.afterClosed().subscribe(result => {
                            this.scheduleObj.refresh();

                        });
                        return false;

                }),
            ).subscribe();
        }

    }

    openUnapprovedRequest(args) {

      debugger;

      if (args.requestType == 'beginEdit') {

        args.cancel = true;
        this.ptoService.getRequest(args.rowData.Id).pipe(
            map(data => {
                    this.dataService.request = data;

                    this.dataService.dialogRef = this.dialog.open(PTORequestComponent, {
                        data: { isManager: this.isManager, isSA: this.isSA, adding: false, fullaccess: true },
                        height: '60%',
                        width: '45%',
                    });
                    this.dataService.dialogRef.afterClosed().subscribe(result => {
                        if (this.isSA) {
                            this.ptoService.getRequestsByStatus('new', this.dataService.year).pipe(
                                map(data => {
                                    this.unapprovedRequests = data;
                                }),
                            ).subscribe();
                        } else {
                            this.loadData();
                            //this.ptoService.getRequestsByApproverStatus(this.account.user.manager, 'new', this.dataService.year).pipe(
                              //  map(data => {
                                //        this.unapprovedRequests = data;
                                //}),
                            //).subscribe();
                
                        }
                        this.scheduleObj.refresh();
                    });
                    return false;

            }),
        ).subscribe();

      }

      
    }

    openDirectory() {
        this.dataService.allusers = [];
        this.dataService.dialogRef = this.dialog.open(NamelistComponent, {
            height: '80%',
            width: '45%',
        });
        this.dataService.dialogRef.afterClosed().subscribe(result => {
            this.loadUsers();
        });
        return false;
    }    

    openProfile() {
        this.dataService.dialogRef = this.dialog.open(ProfileComponent, {
            height: '80%',
            width: '45%',
        });
        this.dataService.dialogRef.afterClosed().subscribe(result => {
            this.loadUsers();
        });
        return false;
    }  

    openHoliday() {
        this.dataService.dialogRef = this.dialog.open(HolidayComponent, {
            data: { isManager: this.isManager, isSA: this.isSA, adding: false },
            height: '40%',
            width: '35%',
        });
        this.dataService.dialogRef.afterClosed().subscribe(result => {
            this.loadHolidays();
        });
        return false;
    }      

    actionBegin(args: SaveEventArgs): void {
        if (args.requestType === 'beginEdit') {
            this.dataService.userData = Object.assign({}, args.rowData);
            this.openProfile();
            args.cancel = true;

        }
        if (args.requestType === 'save') {
            if (this.userForm.valid) {
                args.data = this.userData;
            } else {
                args.cancel = true;
            }
        }
    }

    editHoliday(args: SaveEventArgs): void {
        if (args.requestType === 'beginEdit') {
            this.dataService.userData = Object.assign({}, args.rowData);
            this.openHoliday();
            args.cancel = true;

        }
        if (args.requestType === 'save') {
            if (this.userForm.valid) {
                args.data = this.userData;
            } else {
                args.cancel = true;
            }
        }
    }


}
