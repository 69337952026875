import { Component } from '@angular/core';
import { FormControl } from "@angular/forms";
import { debounceTime, switchMap, startWith } from "rxjs/operators";
import { of } from "rxjs";
import { DataService, ItemsService, AccountService } from '@app/_services';
import { map } from 'rxjs/operators';
import { MatSelectionListChange } from '@angular/material/list';


@Component({
    selector: 'namelist',
    templateUrl: 'namelist.component.html',
  })
  export class NamelistComponent {
    search = new FormControl();
    itemsControl = new FormControl();
    adding = false;
    editing = false;
    item='';
    itemid=0;
    description='';
    constructor(
        public dataService: DataService,
        private itemsService: ItemsService,
        private accountService: AccountService
    ) { 
        this.accountService.getAllFromDirectory().pipe(
            map(items => {
                    this.dataService.itemslist = items;
            }),
        ).subscribe();
    }

    $search = this.search.valueChanges.pipe(
        startWith(null),
        debounceTime(1200),
        switchMap((res: string) => {
            debugger;
            if (!res) return of(this.dataService.itemslist);
            res = res.toLowerCase();
            return of(
                this.dataService.itemslist.filter(x => (x.firstname.toLowerCase().indexOf(res) >= 0 || x.lastname.toLowerCase().indexOf(res) >= 0 || x.managername.toLowerCase().indexOf(res) >= 0))
            );
        })
    );

    delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


      isSelected(obj) {

          const result = this.dataService.allusers.find(({ id }) => id === obj.id);

          console.log(result) // { name: 'cherries', quantity: 5 }

          if (result) {
              obj.selected = true;
            return true;
        } else {
            obj.selected = true;
            return false;
        }
      }

      //selectionChange(obj) {

      selectionChange(event: MatSelectionListChange): void {

        var obj = event.options[0].value;

        if (event.options[0].selected) {  
            //obj = event.options.filter(o => o.selected).map(o => o.value);
            this.dataService.allusers.push({id: obj.id, firstname: obj.firstname, lastname: obj.lastname, email: obj.email, uid: obj.id, manager: obj.manager});
        } else {
            //obj = event.options.filter(o => o.selected).map(o => o.value);
            this.removeObj(this.dataService.allusers, obj.id);
        } 

      }


    removeObj(arr, id) {
    
        for( var i = 0; i < arr.length; i++){ 
                                       
            if ( arr[i].id === id) { 
                arr.splice(i, 1); 
                i--; 
            }
        }
    }  

    itemSelected(obj) {
        debugger;
        
        //alert(1);

        //this.dataService.selecteditem = obj.item;
        //this.dataService.selecteditemid = obj.id;
        //this.dataService.selecteditemdescription = obj.description;
        //this.dataService.dialogRef.close();
    }

    close() {
        debugger;
        this.dataService.dialogRef.close();
    }


 
    cancelItem() {


        this.adding = false;
        this.editing = false;
    }

    importUsers() {
        debugger;
        var body; 

        this.dataService.allusers.map(items => {
            console.log(items);
            body = {
                id: items.id,
                firstname: items.firstname,
                lastname: items.lastname,
                email: items.email,
                manager: items.manager

            }
            this.accountService.create(body).subscribe();
        });

        this.dataService.dialogRef.close();
    }
   

}