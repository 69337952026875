import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Reviews } from '@app/_models';
import { Review } from '@app/_models';
import { QueryOptions } from '@app/_models';
import { DateRangeService } from './daterange.service';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class ConfigService{
    
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private httpClient: HttpClient, private dateRangeService: DateRangeService){ }

    getConfig(): Observable<any>{
        var url = `${baseUrl}/config`;

        console.log('getConfig(): ' + url);

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getDepartments(): Observable<any>{
        var url = `${baseUrl}/config/departments`;

        console.log('getDepartments(): ' + url);

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getSpecialities(dept): Observable<any>{
        var url = `${baseUrl}/config/specialities/department/${dept}`;

        console.log('getSpecialities(): ' + url);

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

}