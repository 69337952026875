import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AccountService, LoadingService} from './_services';
import { Account, Role } from './_models';
import { DataService } from '@app/_services';
import { QueryOptions } from '@app/_models/query-opts';
import { takeUntil, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

//import { DatePickerComponent } from './date-picker/date-picker.component';
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import moment, { Moment } from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    providers: []
})


export class AppComponent implements OnInit
{
    Role = Role;
    account: Account;
    loading: boolean = false;
    searchValue = '';
    isFinal: boolean;
    envMessage: string = '';
    version: string = '';
    queryOptions: QueryOptions = new QueryOptions();
    canComplete = false;
    canAdd = false;
    private leadSub = new Subject(); 
    opens = 'left';
    ranges: any = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment()
            .subtract(1, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ],
        'Last 3 Month': [
          moment()
            .subtract(3, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ]
      };


    constructor(private accountService: AccountService,
                private loadingService: LoadingService,
                public dataService: DataService,
                private route: ActivatedRoute,
                private cookieService: CookieService,
                private router: Router) 
        {
            this.accountService.account.subscribe(x => this.account = x);
            this.version = environment.version;
            this.envMessage = environment.message;

        }

    logout() {

    }
  


    ngOnInit(): void{
        this.listenToLoading();


        //this.dataService.startDate = localStorage.getItem('startDate');
        //this.dataService.endDate = localStorage.getItem('endDate');

        //this.dataService.selectedDates = { startDate: moment(this.dataService.startDate), endDate: moment(this.dataService.endDate) }



    }
    ngAfterViewInit() {
//      var collapsed = localStorage.getItem('collapsed');
        //this.collapse();
        /*
      var collapsed = this.cookieService.get('collapsed');
      
      if (collapsed && collapsed === 'true') {
          this.collapse();
      }
      */
    }



    listenToLoading(): void{
        this.loadingService.loadingSub
            .pipe()
            .subscribe((loading: boolean) => {
                this.loading = loading;
            });
    }

        /* Get the top 5 leads*/
       // this.leadService.getLeads(this.queryOptions, this.account.currentorg.org_id).subscribe(leads => this.companyLeads = leads);


    

}