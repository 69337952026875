import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AccountService } from '@app/_services';
import { ConfigService } from '@app/_services';
import { Account } from '@app/_models';
import { FormsModule } from '@angular/forms';

@Component({ templateUrl: 'list.component.html' })
export class ListComponent implements OnInit {
    accounts: any[];
    selectedAccounts: any[];
    checked = false;
    screen = 1;
    reviewYear = '2022';
    account = this.accountService.accountValue;

    isAdmin: boolean;
    isManager: boolean;

    constructor(private accountService: AccountService,
                private configService: ConfigService
        )
     {}

    ngOnInit() {
     
        this.isAdmin = this.account.user.role === 'admin';
        this.isManager = this.account.user.role === 'manager';



        this.accountService.getAll()
        .pipe(first())
        .subscribe(accounts => this.accounts = accounts);
    }

    deleteAccount(id: string) {
        const account = this.accounts.find(x => x.id === id);
        account.isDeleting = true;
        this.accountService.delete(id)
            .pipe(first())
            .subscribe(() => {
                this.accounts = this.accounts.filter(x => x.id !== id) 
            });
    }

          
}