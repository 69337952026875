import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DataService, PTOService, AccountService, NotificationService } from '@app/_services';
import {FormControl, Validators} from '@angular/forms';
import { map, min } from 'rxjs/operators';
import { ConfirmationComponent } from '@app/_components/confirmation/confirmation.component';
import { subDays } from 'date-fns';
import { JsonpClientBackend } from '@angular/common/http';
import {MatCalendarCellClassFunction} from '@angular/material/datepicker';
import { environment } from '@environments/environment';

const baseUrl = `${environment.siteUrl}`;

@Component({
    selector: 'ptorequest',
    templateUrl: 'ptorequest.component.html',
    styleUrls: ['./ptorequest.component.scss'],
  })
  export class PTORequestComponent {

    myFilter = (d: Date | null): boolean => {
        const day = (d || new Date()).getDay();
        const year = (d || new Date()).getFullYear();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6 && year === this.dataService.year;
      };

    dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
        // Only highligh dates inside the month view.
        if (view === 'month') {

            return this.dataService.holidays[0].find(f => (cellDate >= new Date(f.starttime) && cellDate <= new Date(f.endtime)))
                ? "example-custom-date-class"
                : undefined;

            /*
            this.dataService.holidays[0].forEach(ele => {
                const date = cellDate.getDate();
                const holiDate = new Date(ele.starttime);
                
                console.log(cellDate.getTime());
                console.log(holiDate.getTime());
                return cellDate.getFullYear() === 2022 ? 'example-custom-date-class' : '';
                //return date === 1 || date === 20 ? 'example-custom-date-class' : '';
            })
            */

        }

        return '';
    };      
    
    account = this.accountService.accountValue;
    
    public users: Object[];
    isError = false;
    errorMessages = "";
    adding = false;
    editing = false;
    item='';
    itemid=0;
    description='';
    hours = ['06:00 AM','07:00 AM','08:00 AM','09:00 AM','10:00 AM','11:00 AM','12:00 PM','01:00 PM','02:00 PM','03:00 PM','04:00 PM','05:00 PM'];
    ptoafter:any= null;
    ptoremainingafter:any = null;
    blockedDays: 0;
    minDate: Date;
    maxDate: Date;

    constructor(
        public dataService: DataService,
        private ptoService: PTOService,
        private accountService: AccountService,
        private notificationService: NotificationService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: {isManager: number, isSA: number, adding: boolean, fullaccess: boolean}
    ) { 


        if (this.dataService.request.requestor === this.account.user.id) {
            this.data.fullaccess = true;
        }


        this.adding = data.adding;
        if (data.isManager && !data.isSA) {
            this.users = this.account.employees;
        } else {
            this.accountService.getAllByYear(this.dataService.year).pipe(
                map(users => {
                    this.users = users;
                }),
            ).subscribe();
        }


        if (this.adding) {
            debugger;
            this.dataService.request.requestor = this.account.userinfo.userid;
            this.dataService.request.requestorname = `${this.account.user.lastname}, ${this.account.user.firstname}`;
            this.dataService.request.pto = this.account.userinfo.pto;
            this.dataService.request.ptoremaining = this.account.userinfo.ptoremaining;
            this.dataService.request.starttime = '06:00 AM';
            this.dataService.request.endtime = '05:00 PM';
            this.dataService.request.approverid = this.account.user.manager;
        }


    }

    
   

    delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    approve() {
        var tDate = new Date();

        var body = {
            "status":"approved",
            "approvalnotes": this.dataService.request.approvalnotes,
            "statusdate": tDate.toISOString().slice(0,10) + " " + tDate.getHours() + ":" + tDate.getMinutes(),
            "approverid": this.account.user.id
        }

        this.ptoService.updateRequest(this.dataService.request['Id'], body).subscribe();


        this.accountService.getById(this.dataService.request.requestor)
        .pipe()
        .subscribe((requ: any) => {

            var mailObj = {
                "to": requ.email,
                "subject": "Your PTO request has been approved",
                "text":  this.account.user.firstname + " " + this.account.user.lastname + " approved your PTO Request.",
                "html": this.account.user.firstname + " " + this.account.user.lastname + " approved your PTO Request. FifthColor PTO Database --> <a href='" + baseUrl + "'>link</a>"
            };
    
    
            this.notificationService.sendNotification(mailObj).pipe(
                map( async notification => {
                    console.log(notification);
                })
        
            ).subscribe();
        });


        this.close();
    }

    deny() {
        var tDate = new Date();

        var body = {
            "status":"denied",
            "approvalnotes": this.dataService.request.approvalnotes,
            "statusdate": tDate.toISOString().slice(0,10) + " " + tDate.getHours() + ":" + tDate.getMinutes(),
            "approverid": this.account.user.id
        }

        this.ptoService.updateRequest(this.dataService.request['Id'], body).subscribe();

        // NEED TO GET THE REUQUESTORS PTO #

        this.accountService.userInfo(this.dataService.request.requestor, this.dataService.year).subscribe((xx: any) => {
            
            const body3 = {
                userid: this.dataService.request.requestor,
                before: xx.ptoremaining,
                after: xx.ptoremaining + this.dataService.request.paidhours,
                activity: 'pto request denied',
                pto: this.dataService.request.paidhours * -1,
                year: this.dataService.year

            };

            this.ptoService.addLedger(body3).subscribe();

            this.accountService.getById(this.dataService.request.requestor)
                .pipe()
                .subscribe((requ: any) => {

                    var mailObj = {
                        "to": requ.email,
                        "subject": "Your PTO request has been denied",
                        "text":  this.account.user.firstname + " " + this.account.user.lastname + " denied your PTO Request.",
                        "html": this.account.user.firstname + " " + this.account.user.lastname + " denied your PTO Request. FifthColor PTO Database --> <a href='" + baseUrl + "'>link</a>"
                    };
            
            
                    this.notificationService.sendNotification(mailObj).pipe(
                        map( async notification => {
                            console.log(notification);
                        })
                
                    ).subscribe();
                });
            this.close();

        });


    }

    refund() {
        var tDate = new Date();

        var body = {
            "status":"refunded",
            "approvalnotes": this.dataService.request.approvalnotes,
            "statusdate": tDate.toISOString().slice(0,10) + " " + tDate.getHours() + ":" + tDate.getMinutes(),
            "approverid": this.account.user.id
        }

        this.ptoService.updateRequest(this.dataService.request['Id'], body).subscribe();


        // NEED TO GET THE REUQUESTORS PTO #
        this.accountService.userInfo(this.dataService.request.requestor, this.dataService.year).subscribe((xx: any) => {
            
            const body3 = {
                userid: this.dataService.request.requestor,
                before: xx.ptoremaining,
                after: xx.ptoremaining + this.dataService.request.paidhours,
                activity: `pto request refunded`,
                pto: this.dataService.request.paidhours * -1,
                year: this.dataService.year

            };

            this.ptoService.addLedger(body3).subscribe();

            var body2 = {
                pto: xx.pto - this.dataService.request.paidhours,
                ptoremaining: xx.ptoremaining + this.dataService.request.paidhours
            }
            this.accountService.updatepto(this.dataService.request.requestor , this.dataService.year, body2).subscribe();


            this.accountService.getById(this.dataService.request.requestor)
                .pipe()
                .subscribe((requ: any) => {

                    var mailObj = {
                        "to": requ.email,
                        "subject": "Your PTO request has been refunded",
                        "text":  this.account.user.firstname + " " + this.account.user.lastname + " refunded your PTO Request.",
                        "html": this.account.user.firstname + " " + this.account.user.lastname + " refunded your PTO Request. FifthColor PTO Database --> <a href='" + baseUrl + "'>link</a>"
                    };
            
            
                    this.notificationService.sendNotification(mailObj).pipe(
                        map( async notification => {
                            console.log(notification);
                        })
                
                    ).subscribe();
                });
            this.close();

        });


    }


    submit() {
        if (this.validate()) {

            //verify the requestor has enough pto days available
            if (this.dataService.request.unpaidhours > 0 && !this.dataService.request.nopto) {
                this.dataService.confirmationTitle = 'ALERT';
                this.dataService.confirmationText = 'It appears you do not have enough vacation hours to cover the requested dates. If you proceed, you will not be paid for the days for which you do not have enough PTO. Do you wish to proceed?';
            } else {
                this.dataService.confirmationTitle = 'ALERT';
                this.dataService.confirmationText = 'Are you sure you want to submit this PTO request?';
            }

            var d = this.dialog.open(ConfirmationComponent, {
                width: '400px',
            });
            d.afterClosed().subscribe(result => {
                if (result) {
                    var nowDate = new Date();

                    debugger;

                    var body = {
                        "userid":this.dataService.request.requestor, 
                        "status":"new", 
                        "days":this.dataService.request.days, 
                        "nopaid": this.dataService.request.nopaid, 
                        "nopto": this.dataService.request.nopto,  
                        "partial": this.dataService.request.partial, 
                        "paidhours": this.dataService.request.paidhours,
                        "unpaidhours": this.dataService.request.unpaidhours,
                        'starttime':  this.dataService.request.startdate.toISOString().slice(0, 10) + ' ' + this.dataService.request.startdate.getHours(),
                        "endtime":  this.dataService.request.enddate.toISOString().slice(0, 10) + ' ' + this.dataService.request.enddate.getHours(),
                        "approverid": this.dataService.request.approverid,
                        "statusdate":  nowDate.toISOString().slice(0, 10) + ' ' + nowDate.getHours(),
                        "notes": this.dataService.request.notes,
                        "makeupnotes": this.dataService.request.makeupnotes



                    };

                    this.ptoService.addRequest(body)
                        .pipe(first())
                        .subscribe((xx: any) => {
                            console.log(xx);
                        });

                    var body2 = {
                        pto: this.dataService.request.pto + this.dataService.request.paidhours,
                        ptoremaining: this.dataService.request.ptoremaining - this.dataService.request.paidhours
                        
                    }
                    this.accountService.updatepto(this.dataService.request.requestor , this.dataService.year, body2).subscribe();

                    const body3 = {
                        userid: this.dataService.request.requestor,
                        before: this.dataService.request.ptoremaining,
                        after: this.dataService.request.ptoremaining - this.dataService.request.paidhours,
                        activity: 'pto request',
                        pto: this.dataService.request.paidhours,
                        year: this.dataService.year
                    }

                    this.ptoService.addLedger(body3).subscribe();

                    this.accountService.getById(this.dataService.request.approverid)
                        .pipe()
                        .subscribe((mgr: any) => {
                            console.log(mgr);
                            var mailObj = {
                                "to": mgr.email,
                                "subject": "A new PTO request has been submitted by " + this.dataService.request.requestorname,
                                "text": `${this.dataService.request.requestorname} has submitted a PTO Request.`,
                                "html": `${this.dataService.request.requestorname} has submitted a PTO Request. FifthColor PTO Database --> <a href="${baseUrl}">link</a>`
                              };
                        
                              
                        
                              this.notificationService.sendNotification(mailObj).pipe(
                                map( async notification => {
                                    console.log(notification);
                                })
                              
                              ).subscribe();
                        });

                    






                    this.dataService.dialogRef.close(1);
                } else {

                }

            });

            
        } else {
            
        }
    }
    


 
    close() {
        debugger; 
        this.dataService.dialogRef.close(0);
    }


    updateRequestor() {

        //this.dataService.request.pto = null;
        //this.dataService.request.ptoremaining = null;

        debugger;
        this.accountService.userInfo(this.dataService.request.requestor, this.dataService.year).subscribe((xx: any) => {
            this.dataService.request.requestorname = `${xx.lastname}, ${xx.firstname}`;
            this.dataService.request.pto = xx.pto;
            this.dataService.request.ptoremaining = xx.ptoremaining;
            this.dataService.request.approverid = xx.manager;
        });


    }


    validate() {

        if (!this.dataService.request.startdate) {
            this.isError = true;
            this.errorMessages = "Start date is required";
            return false;
        }

        if (!this.dataService.request.startdate) {
            this.isError = true;
            this.errorMessages = "End date is required";
            return false;
        }
        this.calculateBusinessDays(this.dataService.request.startdate, this.dataService.request.enddate);
        this.isError = false;
        this.errorMessages = '';
        return true;

    }

    changeDate() {

        if (!this.dataService.request.enddate) {
            this.dataService.request.enddate = this.dataService.request.startdate;
        }

        // is this a partial date?
        //if (this.dataService.request.partial) {

        this.dataService.request.startdate = new Date(`${this.dataService.request.startdate.toString().slice(0,15)} ${this.dataService.request.starttime}`)
        this.dataService.request.enddate = new Date(`${this.dataService.request.enddate.toString().slice(0,15)} ${this.dataService.request.endtime}`)
        //}

        this.calculateBusinessDays(this.dataService.request.startdate, this.dataService.request.enddate);
    }

    calculateBusinessDays(tStartDate, tEndDate){
        // Validate input
        if (tEndDate < tStartDate) {
            return 0;
        }
        
   
        var strStartDate = this.dataService.request.startdate.toISOString().slice(0, 10);// + ' ' + this.dataService.request.startdate.getHours() + ':00:00';
        var strEndDate = this.dataService.request.enddate.toISOString().slice(0, 10);// + ' ' + this.dataService.request.enddate.getHours() + ':00:00';

        this.ptoService.getBlocked(strStartDate, strEndDate).pipe(
            map(blockedDays => {
                var startDate: any = new Date(tStartDate);
                var endDate:any = new Date(tEndDate);
        
                // Calculate days between dates
                var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
                startDate.setHours(0,0,0,1);  // Start just after midnight
                endDate.setHours(23,59,59,999);  // End just before midnight
                var diff = endDate - startDate;  // Milliseconds between datetime objects    
                var days = Math.ceil(diff / millisecondsPerDay);
                
                // Subtract two weekend days for every week in between
                var weeks = Math.floor(days / 7);
                days = days - (weeks * 2);
                
                // Handle special cases
                var startDay = startDate.getDay();
                var endDay = endDate.getDay();
                
                // Remove weekend not previously removed.   
                if (startDay - endDay > 1)         
                    days = days - 2;      
                
                // Remove start day if span starts on Sunday but ends before Saturday
                if (startDay == 0 && endDay != 6) {
                    days = days - 1;  
                }
                
                // Remove end day if span ends on Saturday but starts after Sunday
                if (endDay == 6 && startDay != 0) {
                    days = days - 1;
                }
        
                // if 1 day and partial
                if (days === 1 && this.dataService.request.partial) {
                    var timeStart = new Date("01/01/2007 " + this.dataService.request.starttime).getHours();
                    var timeEnd = new Date("01/01/2007 " + this.dataService.request.endtime).getHours();
        
                    var hourDiff = timeEnd - timeStart;
                    days = Math.min(hourDiff/8,1);
                }
        
                days = days - blockedDays.length;


                this.dataService.request.days = days;
        
                var hours = days * 8;
        
                //if (hours > 2) {
                  //  this.dataService.request.nopto = 0;
                //}
        
                if (days > 1) {
                    this.dataService.request.partial = false;
                    //this.dataService.request.nopto = 0;
                }
        
                if (this.dataService.request.nopto) {
                    this.dataService.request.paidhours = 0;
                    this.dataService.request.unpaidhours = hours;
                } else {
                    
                    if (hours > this.dataService.request.ptoremaining) {
                        this.dataService.request.paidhours = this.dataService.request.ptoremaining;
                        this.dataService.request.unpaidhours = hours - this.dataService.request.ptoremaining;
                    } else {
                        this.dataService.request.paidhours = hours;
                        this.dataService.request.unpaidhours = 0;
                    }
                }
        
                this.ptoafter = '(' + (this.dataService.request.pto + this.dataService.request.paidhours) + ')';
                this.ptoremainingafter = '(' + (this.dataService.request.ptoremaining - this.dataService.request.paidhours) + ')';
        

                this.isError = false;
                this.errorMessages = '';
                if (days === 0) {
                    this.isError = true;
                    this.errorMessages = 'The days you have chosen are are blocked';
                }
            }),
        ).subscribe();


/*


        // what if the pto requested is > pto remaining?
        if (!this.dataService.request.nopto && days) > this.dataService.request.ptoremaining) {
            // only count the difference

            // would always be 0
            this.ptoremainingafter = '(0)';

            // calculate paid and unpaid hours
            var diff = days - this.dataService.request.ptoremaining;
            this.ptoafter = '(' + (this.dataService.request.pto + days - diff) + ')';
            this.dataService.request.paidhours = this.dataService.request.ptoremaining * 8;
            this.dataService.request.unpaidhours = diff * 8;

        } else {
            // choosing to not use pto
            if (this.dataService.request.nopto) {
                this.ptoafter = '(' + (this.dataService.request.pto) + ')';
                this.ptoremainingafter = '(' + (this.dataService.request.ptoremaining) + ')';
            } else {
                this.ptoafter = '(' + (this.dataService.request.pto + days) + ')';
                this.ptoremainingafter = '(' + (this.dataService.request.ptoremaining - days) + ')';
            }
        }
  */      



    }

    getApprover() {
         // NEED TO FIND THE MANAGER
         var approverId;
         // if SA - search for manager
         if (this.data.isSA) {
             approverId = this.account.user.manager;
         } else if (this.data.isManager) {
             //approverId = this.account.user.id;
             approverId = this.account.user.manager;
         } else {
             approverId = this.account.user.manager;
         }
         return approverId;
    }

}