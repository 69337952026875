import { Component } from '@angular/core';
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, switchMap, startWith } from "rxjs/operators";
import { of } from "rxjs";
import { DataService, ItemsService, AccountService } from '@app/_services';
import { map } from 'rxjs/operators';
@Component({
    selector: 'managerlist',
    templateUrl: 'managerlist.component.html',
  })
  export class ManagerlistComponent {
    search = new UntypedFormControl();
    itemsControl = new UntypedFormControl();
    adding = false;
    editing = false;
    item='';
    itemid=0;
    description='';
    constructor(
        public dataService: DataService,
        private itemsService: ItemsService,
        private accountService: AccountService
    ) { 
        this.accountService.getAll().pipe(
            map(items => {
                    this.dataService.itemslist = items;
            }),
        ).subscribe();
    }

    $search = this.search.valueChanges.pipe(
        startWith(null),
        debounceTime(1200),
        switchMap((res: string) => {
            debugger;
            if (!res) return of(this.dataService.itemslist);
            res = res.toLowerCase();
            return of(
                this.dataService.itemslist.filter(x => (x.firstname.toLowerCase().indexOf(res) >= 0 || x.lastname.toLowerCase().indexOf(res) >= 0))
            );
        })
    );

    delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }



      selectionChange(obj) {
        //this.dataService.selectedManager = obj.id;

        this.dataService.dialogRef.close(obj);
      }


 

    itemSelected(obj) {
        debugger;
        
        //alert(1);

        //this.dataService.selecteditem = obj.item;
        //this.dataService.selecteditemid = obj.id;
        //this.dataService.selecteditemdescription = obj.description;
        //this.dataService.dialogRef.close();
    }

    close() {
        debugger;
        this.dataService.dialogRef.close();
    }




    

}