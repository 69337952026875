<div class="dashintro" *ngIf="account">  <!-- Move this to component -->
    <div class="row justify-content-between align-items-center">
        <div class="col-9">
            <svg xmlns="http://www.w3.org/2000/svg" width="261.059" height="20.685" viewBox="0 0 261.059 20.685">
                <g id="_5th_Color_Wordmark" data-name="5th_Color_Wordmark" transform="translate(20 -2.1)">
                  <rect id="Rectangle_498" data-name="Rectangle 498" width="4.119" height="19.777" transform="translate(19.246 2.521)"/>
                  <path id="Path_20818" data-name="Path 20818" d="M133.5,23.777h4.075v-7.53h8.172V13.036h-8.172V7.256h11.693V4H133.5Z" transform="translate(-103.934 -1.479)"/>
                  <path id="Path_20819" data-name="Path 20819" d="M0,23.777H4.075v-7.53h8.172V13.036H4.075V7.256H15.768V4H0Z" transform="translate(0 -1.479)"/>
                  <path id="Path_20820" data-name="Path 20820" d="M222.2,7.256h6.688V23.777h4.075V7.256h6.71V4H222.2Z" transform="translate(-172.99 -1.479)" fill="#ee2d26"/>
                  <path id="Path_20821" data-name="Path 20821" d="M338.23,13.036H328.153V4H324.1V23.777h4.053v-7.53H338.23v7.53H342.3V4H338.23Z" transform="translate(-252.322 -1.479)" fill="#ee2d26"/>
                  <path id="Path_20822" data-name="Path 20822" d="M458.737,17.681a6.663,6.663,0,0,1-4.584,1.683c-3.654,0-6.423-2.946-6.423-6.865s2.768-6.865,6.423-6.865a6.642,6.642,0,0,1,4.363,1.572l.687.576,3.1-2.3L461.24,4.57a10.25,10.25,0,0,0-7.021-2.37c-6.113,0-10.719,4.429-10.719,10.32a10.18,10.18,0,0,0,10.475,10.343,10.319,10.319,0,0,0,7.419-2.7l1.041-.974L459.4,17.1Z" transform="translate(-345.279 -0.078)"/>
                  <path id="Path_20823" data-name="Path 20823" d="M557.807,2.1c-6.179,0-11.007,4.54-11.007,10.32,0,5.891,4.717,10.343,10.963,10.343,6.157,0,10.985-4.54,10.985-10.343C568.725,6.529,564.03,2.1,557.807,2.1Zm0,17.23a6.784,6.784,0,0,1-6.8-6.91,6.686,6.686,0,0,1,6.755-6.932,6.8,6.8,0,0,1,6.8,6.932A6.707,6.707,0,0,1,557.807,19.33Z" transform="translate(-425.702)"/>
                  <path id="Path_20824" data-name="Path 20824" d="M676.075,4H672V23.777h13.288V20.544h-9.213Z" transform="translate(-523.174 -1.479)"/>
                  <path id="Path_20825" data-name="Path 20825" d="M758.829,2.1C752.65,2.1,747.8,6.64,747.8,12.42c0,5.891,4.717,10.343,10.963,10.343,6.179,0,11.007-4.54,11.007-10.343C769.77,6.529,765.074,2.1,758.829,2.1Zm0,17.23a6.784,6.784,0,0,1-6.8-6.91,6.663,6.663,0,0,1,6.71-6.932,6.807,6.807,0,0,1,6.821,6.932A6.649,6.649,0,0,1,758.829,19.33Z" transform="translate(-582.187)"/>
                  <path id="Path_20826" data-name="Path 20826" d="M886.853,16.269a5.779,5.779,0,0,0,3.942-5.78A5.746,5.746,0,0,0,889.156,6.1c-1.373-1.4-3.566-2.1-6.555-2.1h-9.5V23.777h4.053V7.211h5.116c2.724,0,4.429,1.373,4.429,3.61s-1.705,3.676-4.363,3.676h-2.724l6.423,9.279h4.584l-4.939-7.021Z" transform="translate(-679.737 -1.479)"/>
                </g>

            </svg>

        </div>
        <div class="col-1">
            <!--<h6 style="color: red">{{currentTabName}}</h6>
            <h6 style="color: red">System Year: {{dataService.year==dataService.configYear}}</h6>
            <h6 style="color: red">Config Year: {{dataService.configYear}}</h6>
            <h6 style="color: red">{{currentTab}}</h6>-->

            
            <mat-form-field appearance="fill" readonly >
                <mat-select id="year" [(ngModel)]="dataService.year" (selectionChange)="updateYear()">
                    <mat-option #matOption *ngFor="let yr of years" [value]=yr>{{yr}}</mat-option>
                </mat-select>
            </mat-form-field>
            
            <div *ngIf="dataService.year!=dataService.configYear" style="color: red; font-size: 11px">(Viewing an inactive year)</div>
        </div>
        <div class="col-1">
            <div class="navg2">


                    <a class="btn btn-danger" aria-label="Logout"  (click)="accountService.logout()">
                        <i class="fa fa-sign-out" aria-hidden="true"></i>
                    </a>

            </div>
        </div>

    </div>
</div>

<mat-tab-group #tab style="background-color: #f2f2f2; padding:20px"  (selectedTabChange)="onTabClick($event)" [selectedIndex]="currentTab">
    <mat-tab label="Home"  #hometab>
      
        <div id="settingsdiv" class="settingsdiv w3-container" style="width: 60%; margin-top: 10px">

         <!--   <div class="w3-container w3-blue w3-margin-bottom">
                <h2>Profile</h2>
            </div>-->
            
            <form>
                <div class="w3-container w3-row-padding">
                    <div class="w3-col m6 l6">
                        <h4 class="w3-margin-top"><b>{{account.user.firstname}} {{account.user.lastname}}</b></h4>
                    </div>
                </div>
                <div class="w3-container w3-row-padding">
                    <div class="w3-col m3 l3">
                        <label class="w3-margin-top"><b>Manager</b></label>
                    </div>

                    <div class="w3-col m9 l9">
                        <label class="w3-margin-top">{{account.user.managername}}</label>
                    </div>
                </div> 

                <div class="w3-container w3-row-padding">
                    <div class="w3-col m3 l3">
                        <label class="w3-margin-top"><b>Department</b></label>
                    </div>

                    <div class="w3-col m9 l9">
                        <label class="w3-margin-top">{{account.userinfo.departmentname}}/{{account.userinfo.specialtyname}}</label>
                    </div>
                </div>

                <div class="w3-container w3-row-padding">
                    <div class="w3-col m3 l3">
                        <label class="w3-margin-top"><b>PTO Hours Used</b></label>
                    </div>

                    <div class="w3-col m9 l9">
                        <label class="w3-margin-top">{{account.userinfo.pto}}</label>
                    </div>
                </div>                                

                <div class="w3-container w3-row-padding">
                    <div class="w3-col m3 l3">
                        <label class="w3-margin-top"><b>PTO Hours Remaining</b></label>
                    </div>

                    <div class="w3-col m9 l9">
                        <label class="w3-margin-top">{{account.userinfo.ptoremaining}}</label>
                    </div>
                </div>      
                                          

                <div class="w3-container w3-row-padding w3-margin-top" *ngIf="dataService.year>=dataService.configYear">
                    <div class="w3-col m3 l3">
                        <button class="w3-btn w3-blue-grey w3-margin-top" (click)="requestPTO()">Request Time Off</button>
                    </div>
                </div>
            </form>
        
        </div>

        
    </mat-tab>
    
    <mat-tab label="Calendar" #calendarTab>
        <div class="row">
            <div class="col-9"></div>
            <div class="col-1 w3-margin-top">
                Filter by person
            </div>
            <div class="col-1">
                <mat-form-field appearance="fill" readonly >

                <mat-select id="requestor" [(ngModel)]="calendarRequestor" (selectionChange)="loadCalendar()">
                    <mat-option [value]=0>-- All --</mat-option>
                  <mat-option [value]="account.user.id">{{ account.user.lastname }}, {{ account.user.firstname }}</mat-option>
                  <mat-option #matOption *ngFor="let user of users" [value]="user.id">{{ user.lastname }}, {{ user.firstname }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
        <ejs-schedule #scheduleObj cssClass='schedule-block-events' width='100%' height='650px' [readonly]="readonly" [selectedDate]="selectedDate" [eventSettings]="eventSettings" (eventRendered)="applyCategoryColor($event)"  [showQuickInfo]="showQuickInfo" (popupOpen)='onPopupOpen($event)' currentView="Month">
            <e-views>
                <e-view option="Day" startHour="06:00" endHour="18:00" [eventTemplate]="dayEventTemplate"></e-view>
                <e-view option="Week" startHour="06:00" endHour="18:00" [eventTemplate]="dayEventTemplate"></e-view>
                <e-view option="WorkWeek" startHour="06:00" endHour="18:00" [eventTemplate]="dayEventTemplate"></e-view>
                <e-view option="Month" [eventTemplate]="monthEventTemplate"></e-view>
                <e-view option="Agenda" [eventTemplate]="agendaEventTemplate"></e-view>
              </e-views>

              <ng-template #dayEventTemplate let-data>
                <p *ngIf="data.Subject">{{data.Subject}} - {{data.status}}</p>
                <p *ngIf="!data.Subject">{{data.notes}}</p>
              </ng-template>

              <ng-template #monthEventTemplate let-data>
                <p *ngIf="data.Subject">{{data.Subject}} - {{data.status}}</p>
                <p *ngIf="!data.Subject">{{data.notes}}</p>
              </ng-template>

              <ng-template #agendaEventTemplate let-data>
                <p *ngIf="data.Subject">{{data.Subject}} - {{data.status}}<br>{{data.StartTime | date: 'short'}} - {{data.EndTime | date: 'short'}}</p>
                
                <p *ngIf="!data.Subject">{{data.notes}}</p>
              </ng-template>


              <ng-template #editorTemplate>
                <table class="custom-event-editor" width="100%" cellpadding="5">
                    <tbody>
                        <tr>
                            <td class="e-textlabel">Requestor</td>
                            <td colspan="4">
                                <input id="Subject" class="e-field e-input" type="text" value="" disabled name="Subject" style="width: 100%" />
                            </td>
                        </tr>
                        <tr>
                            <td class="e-textlabel">Status</td>
                            <td colspan="4">
                                <input type="text" id="status" name="status" class="e-field e-input" style="width: 100%" disabled />
                            </td>
                        </tr>
                        <tr>
                            <td class="e-textlabel">From</td>
                            <td colspan="4">
                                <input id="StartTime" class="e-field" type="text" name="StartTime" disabled />
                            </td>
                        </tr>
                        <tr>
                            <td class="e-textlabel">To</td>
                            <td colspan="4">
                                <input id="EndTime" class="e-field" type="text" name="EndTime" disabled />
                            </td>
                        </tr>
                        <tr>
                            <td class="e-textlabel">Notes</td>
                            <td colspan="4">
                                <textarea id="Description" class="e-field e-input" disabled name="description" rows="3" cols="50" style="width: 100%; height: 60px !important; resize: vertical"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-template>
        </ejs-schedule>
    </mat-tab>
    
    <mat-tab label="Unapproved Requests ({{unapprovedRequests.length}})" *ngIf="isManager || isSA"  #unapprovedTab>
        <div class="control-section">
            <ejs-grid #grid [dataSource]='unapprovedRequests' allowPaging='true' [pageSettings]='pageSettings' [editSettings]='editSettings' [toolbar]='toolbar' (actionBegin)='openUnapprovedRequest($event)'>
                <e-columns>
                    <e-column field='status' headerText='Status' width='60' textAlign='Left'></e-column>
                    <e-column field='Subject' headerText='Requestor' width='160'></e-column>
                    <e-column field='StartTime' headerText='Start' width='120' textAlign='Left' [valueAccessor]='dateFormatter'></e-column>
                    <e-column field='EndTime' headerText='End' width='150' textAlign='Left' [valueAccessor]='dateFormatter'></e-column>
                    <e-column field='paidhours' headerText='Paid Hours' width='50' ></e-column>
                    <e-column field='unpaidhours' headerText='Unpaid Hours' width='50' ></e-column>
                </e-columns>
            </ejs-grid>
        
        </div>
    </mat-tab>
  
    <mat-tab label="History"  #historyTab>
        <div class="row" *ngIf="this.isSA || this.isManager">

            <div class="col-4">
                <mat-radio-group [(ngModel)]="historyType" >
                  <mat-radio-button class="w3-margin-left w3-margin-right w3-margin-top" value="requests">PTO Requests</mat-radio-button>
                  <mat-radio-button value="ledger" >Ledger</mat-radio-button>
                </mat-radio-group>
            </div>


            <div class="col-5"></div>
            <div class="col-1 w3-margin-top">
                Filter by person
            </div>
            <div class="col-1">
                <mat-form-field appearance="fill" readonly >
                <mat-select id="history" [(ngModel)]="dataService.request.requestor" (selectionChange)="loadHistory()">
                    <mat-option [value]="account.userinfo.userid">{{ account.user.lastname }}, {{ account.user.firstname }}</mat-option>
                    <mat-option #matOption *ngFor="let user of users" [value]="user.id">{{ user.lastname }}, {{ user.firstname }}</mat-option>
                </mat-select>
                </mat-form-field>
            </div>
        </div>


        <div class="control-section">
            <mat-radio-group [(ngModel)]="historyType"  *ngIf="!this.isSA && !this.isManager">
                <mat-radio-button class="w3-margin-left w3-margin-right w3-margin-top" value="requests">PTO Requests</mat-radio-button>
                <mat-radio-button value="ledger" >Ledger</mat-radio-button>
            </mat-radio-group>

            <ejs-grid *ngIf="historyType==='requests'" #grid [dataSource]='requestHistory' allowPaging='true' [pageSettings]='pageSettings' [editSettings]='editSettings' [toolbar]='toolbar' (actionBegin)='openUnapprovedRequest($event)'  class="w3-margin-top">
                <e-columns>
                    <e-column field='status' headerText='Status' width='60' textAlign='Left'></e-column>
                    <e-column field='Subject' headerText='Requestor' width='160'></e-column>
                    <e-column field='StartTime' headerText='Start' width='120' textAlign='Left' [valueAccessor]='dateFormatter'></e-column>
                    <e-column field='EndTime' headerText='End' width='150' textAlign='Left' [valueAccessor]='dateFormatter'></e-column>
                    <e-column field='paidhours' headerText='Paid Hours' width='50' ></e-column>
                    <e-column field='unpaidhours' headerText='Unpaid Hours' width='50' ></e-column>
                </e-columns>
            </ejs-grid>

            <ejs-grid *ngIf="historyType==='ledger'" #grid [dataSource]='ledgerHistory' allowPaging='true' [pageSettings]='pageSettings'>
                <e-columns>
                    <e-column field='created' headerText='Date' [valueAccessor]='dateFormatter'></e-column>
                    <e-column field='activity' headerText='Activity' textAlign='Left'></e-column>
                    <e-column field='before' headerText='PTO Before'  textAlign='Left'></e-column>
                    <e-column field='after' headerText='PTO After' ></e-column>
                    <e-column field='pto' headerText='PTO Amount' textAlign='Left' ></e-column>


                </e-columns>
            </ejs-grid>            
        </div>

    </mat-tab>

    <mat-tab label="Users" #usersTab *ngIf="isSA">
        <div class="control-section">
            <ejs-grid #grid [dataSource]='users'  [editSettings]='editSettings' [toolbar]='toolbar' (actionBegin)='actionBegin($event)'>
                <e-columns>
                    <e-column field='email' headerText='Email' width='120' textAlign='Left'></e-column>
                    <e-column field='firstname' headerText='First Name' width='160'></e-column>
                    <e-column field='lastname' headerText='Last Name' width='120'></e-column>
                    <e-column field='department' headerText='Department' width='150'></e-column>
                    <e-column field='specialty' headerText='Specialty' width='150' ></e-column>
                    <e-column field='pto' headerText='PTO Used' width='150' ></e-column>
                    <e-column field='ptoremaining' headerText='PTO Remaining' width='150' ></e-column>
                </e-columns>
            </ejs-grid>
            <button class="w3-btn w3-blue-grey w3-margin-top" (click)="openDirectory()">Import from Directory</button>
        </div>
    </mat-tab>

    <mat-tab label="Holidays" #holidaysTab *ngIf="isSA">
        <div class="control-section">
            <ejs-grid #grid [dataSource]='holidays' allowPaging='true' [pageSettings]='pageSettings' [editSettings]='editSettings' [toolbar]='toolbar' (actionBegin)='editHoliday($event)'>
                <e-columns>
                    <e-column field='notes' headerText='Description' width='200' textAlign='Left'></e-column>
                    <e-column field='StartTime' headerText='Start' width='120' textAlign='Left' [valueAccessor]='dateFormatter'></e-column>
                    <e-column field='EndTime' headerText='End' width='150' textAlign='Left' [valueAccessor]='dateFormatter'></e-column>
                </e-columns>
            </ejs-grid>
        
            <button class="w3-btn w3-blue-grey w3-margin-top" (click)="addHoliday()">Add Holiday</button>
        </div>

 
    </mat-tab>

    <mat-tab label="PTO Policy"  #policyTab id="policy">
        <h1 class="w3-margin">FifthColor PTO Policy</h1>
        <p class="w3-margin-top"> 
            <ul class="w3-margin-top">
                <li>We reserve the right to deny request if too many people have already requested the dates off and/or it may affect operations.</li>
                <li>Vacation days may not be carried forward into the next year.</li>
                <li>Each request must be submitted separately if dates do not fall in consecutive order.</li>
            </ul>
        
    </mat-tab>
  </mat-tab-group>

  

<!--
<div class="row"  style="margin-bottom: 10px;" *ngIf="account.user.role === 'manager' || this.account.user.role === 'admin'">
    <div class="col-lg-12">
        <div class="card table-card">
            <div class="row">
                <div class="col-lg-6">
                    <h3 class="card-title">{{viewtitle}}</h3>
                </div>
            </div>
            
            <div class="row" style="height: 10px"></div>
            <div class="row">
                <table class="table table-striped table-borderless table-hover">
                    <thead>
                        <tr>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Year</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort">
                                    <span>Employee</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort">
                                    <span>Status</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort">
                                    <span>Title</span>
                                </a>
                            </td>      
                                                                         
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort">
                                    <span>Manager</span>
                                </a>
                            </td>

                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let review of dataService.reviews | paginate: { id: 'pagination1', itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords }" (click)="openReview(review)">       
                            <td> {{ review.year }} </td>
                            <td> {{ review.employee }} </td>
                            <td> {{ review.status }} </td>
                            <td> {{ review.title }} </td>

                            <td> {{ review.manager }} </td>
                        </tr>
                    </tbody>
                </table>
                <div class="cardFoot">
                    <pagination-controls id="pagination1"  previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="row"  style="margin-bottom: 10px;" >
    <div class="col-lg-12">
        <div class="card table-card">
            <div class="row">
                <div class="col-lg-6">
                    <h3 class="card-title">{{viewtitle2}}</h3>
                </div>
            </div>
            
            <div class="row" style="height: 10px"></div>
            <div class="row">
                <table class="table table-striped table-borderless table-hover">
                    <thead>
                        <tr>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Year</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Employee</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Status</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort">
                                    <span>Evaluator</span>
                                </a>
                            </td>    
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Manager</span>
                                </a>
                            </td>

                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let evaluation of dataService.evaluations | paginate: { id: 'pagination1', itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords }" (click)="openEvaluation(evaluation)">       
                            <td> {{ evaluation.year }} </td>
                            <td> {{ evaluation.employee }} {{evaluation.employee == evaluation.evaluator ? '(SELF)' : ''}}</td>
                            <td> {{ evaluation.evaluationstatus }} </td>
                            <td> {{ evaluation.evaluator }} </td>                            
                            <td> {{ evaluation.manager }} </td>
                        </tr>
                    </tbody>
                </table>
                <div class="cardFoot" *ngIf="this.account.user.role === 'manager' || this.account.user.role === 'admin'">
                    <pagination-controls id="pagination2" previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange2($event)"></pagination-controls>
                </div>
            </div>

        </div>
    </div>
</div>
-->
