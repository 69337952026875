<div *ngIf="!adding && !editing" >

  <div class="row">
    <div class="col-11">
      <mat-form-field style="width:90%" autocomplete="off">
        <mat-label>Search</mat-label>
        <input matInput [formControl]="search" autocomplete="off" type="search">
      </mat-form-field>
    </div>
    <div class="col-1">
      <i class='fas fa-window-close' style='font-size:24px;color:gray; float: right;' (click)="close()"></i>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-dialog-content class="mat-typography">
        <h2 mat-dialog-title>Names</h2>
        <mat-selection-list #items [multiple]="true" (selectionChange)="selectionChange($event)">
          <div class="row">
            <div class="col-3">Last Name</div>
            <div class="col-3">First Name</div>
            <div class="col-3">Manager</div>
            <div class="col-3"></div>
          </div>
          <hr>
          <!--<mat-list-option style="height: auto" *ngFor="let item of $search|async" [value]="item" [selected]="itemsControl.value &&itemsControl.value?.indexOf(item)>=0">-->
          <mat-list-option style="height: auto" *ngFor="let item of $search|async"  [value]="item" [selected]="isSelected(item)">
                    
            <div class="row" style="height: auto; min-height: 40px;">
              <div class="col-3">{{item.firstname}}</div>
              <div class="col-3">{{item.lastname}}</div>
              <div class="col-3">{{item.managername}}</div>
              <div class="col-3">{{item.value}}</div>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </mat-dialog-content>
    </div>
  </div>
</div>

<button class="w3-btn w3-blue-grey w3-margin-top" (click)="importUsers()">Import</button>

