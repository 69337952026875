<div *ngIf="loading" class="loading-container flex-content-center">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>


<!-- Profile menu; header? Put this in its own component -->
<div [ngClass]="{'pl-lg-4 container-fluid': account}">
    <div class="subnav" *ngIf="account">
        <div class="row">
            
            <div class="col-sm-5 col-xl-4" *ngIf="(router.url === '/orders/allorders')">
                <div class="sn-search" > 
                    <input id="searchInput" class="globalsearch" type="search" placeholder="PO#, Job Ticket, Requestor etc." [(ngModel)]="searchValue"/>
                    
                </div>
            </div>
        
            <div class="col-sm-11 col-xl-11">
                  <div class="sn-btns d-flex align-items-center justify-content-end">

                  <!--
                    <div class="accountDD ms-3 dropdown">
                        <button class="aDDbtn btn btn-common btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>{{account.user.firstname}}</span> 
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <<li><a class="dropdown-item" routerLink="/account/profile" routerLinkActive="active">Profile</a></li>
                            <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
                        </ul>
                    </div>
                -->
                    <!--
                    <div class="accountDD ms-3 dropdown" *ngIf="(router.url === '/leads/dashboard' || router.url === '/leads/lead-view')">
                        <div class="accountDD ms-3 dropdown">
                            <button class="aDDbtn btn btn-common btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>{{dataService.currentOrg.name}}</span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li *ngFor="let org of account.userorgs">
                                    <a class="dropdown-item" (click)="changeOrg(org)">{{org.name}}</a>
                                </li>
                        
                            </ul>
                        </div>
                    </div>
                    -->
                              
                </div>
            </div>
        </div>
       

       <!-- <div class="row" *ngIf="dataService.showSimpleDatePicker">
            <div class="col-lg-12">
                <rr-simpledatepicker></rr-simpledatepicker>
            </div>
        </div>-->
    </div>

    <!-- global alerts -->
    <alert></alert>
    
    <router-outlet></router-outlet>

          
    
 
</div>