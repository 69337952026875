import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DataService, PTOService, AccountService } from '@app/_services';
import {FormControl, Validators} from '@angular/forms';
import { map, min } from 'rxjs/operators';
import { ConfirmationComponent } from '@app/_components/confirmation/confirmation.component';
import { subDays } from 'date-fns';
import { JsonpClientBackend } from '@angular/common/http';

@Component({
    selector: 'holiday',
    templateUrl: 'holiday.component.html'

  })
  export class HolidayComponent {
    myFilter = (d: Date | null): boolean => {
        const day = (d || new Date()).getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
      };
    
    account = this.accountService.accountValue;
    
    public users: Object[];
    isError = false;
    errorMessages = "";
    adding = false;
    editing = false;
    item='';
    itemid=0;
    description='';
    hours = ['06:00 AM','07:00 AM','08:00 AM','09:00 AM','10:00 AM','11:00 AM','12:00 PM','01:00 PM','02:00 PM','03:00 PM','04:00 PM','05:00 PM'];
    ptoafter:any= null;
    ptoremainingafter:any = null;

    

    constructor(
        public dataService: DataService,
        private ptoService: PTOService,
        private accountService: AccountService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: {isManager: number, isSA: number, adding: boolean}
    ) { 
        debugger;
        this.adding = data.adding;


        if (this.adding) {
            debugger;
            this.dataService.request.requestor = this.account.userinfo.userid;
            this.dataService.request.requestorname = `${this.account.user.lastname}, ${this.account.user.firstname}`;
            this.dataService.request.pto = this.account.userinfo.pto;
            this.dataService.request.ptoremaining = this.account.userinfo.ptoremaining;
            this.dataService.request.starttime = '06:00 AM';
            this.dataService.request.endtime = '05:00 PM';
            this.dataService.request.approverid = this.account.user.manager;

        }


    }

    
   

    delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

  
   
    


 
    close() {
        debugger; 
        this.dataService.dialogRef.close(0);
    }


    save() {

        this.dataService.confirmationTitle = 'ALERT';
        this.dataService.confirmationText = 'Are you sure you want to save this holiday?';

        var d = this.dialog.open(ConfirmationComponent, {
            width: '400px',
        });
        d.afterClosed().subscribe(result => {
            if (result) {
                var nowDate = new Date();

                debugger;


                var startDate = this.dataService.userData.StartTime;
                var endDate = this.dataService.userData.EndTime;

                if (typeof startDate === 'object') {
                    startDate = startDate.toISOString();
                    endDate = endDate.toISOString();
                }

                var body = {
                    'starttime':  startDate.slice(0, 10) + ' ' + startDate.slice(11, 19),
                    "endtime":  endDate.slice(0, 10) + ' ' + endDate.slice(11, 19),
                    "statusdate":  nowDate.toISOString().slice(0, 10) + ' ' + nowDate.getHours(),
                    "notes": this.dataService.userData.notes
                };

                this.ptoService.updateRequest(this.dataService.userData.Id, body)
                    .pipe(first())
                    .subscribe((xx: any) => {
                        console.log(xx);
                    });

                this.dataService.dialogRef.close(1);
            } else {

            }

        });

            

    }

    submit() {
        if (this.validate()) {



            this.dataService.confirmationTitle = 'ALERT';
            this.dataService.confirmationText = 'Are you sure you want to save this holiday?';

            var d = this.dialog.open(ConfirmationComponent, {
                width: '400px',
            });
            d.afterClosed().subscribe(result => {
                if (result) {
                    var nowDate = new Date();

                    debugger;

                    var body = {
                        "userid": 0, 
                        "status":"blocked", 
                        "partial": 0,
                        "paidhours": 0,
                        "unpaidhours": 0,
                        "days":this.dataService.request.days, 
                        'starttime':  this.dataService.request.startdate.toISOString().slice(0, 10) + ' 00:00:00',
                        "endtime":  this.dataService.request.enddate.toISOString().slice(0, 10) + ' 23:59:59',
                        "approverid": 0,
                        "statusdate":  nowDate.toISOString().slice(0, 10) + ' ' + nowDate.getHours(),
                        "notes": this.dataService.request.notes
                    };

                    this.ptoService.addRequest(body)
                        .pipe(first())
                        .subscribe((xx: any) => {
                            console.log(xx);
                        });

                    this.dataService.dialogRef.close(1);
                } else {

                }

            });

            
        } else {
            
        }
    }

    validate() {
        if (!this.dataService.request.notes) {
            this.isError = true;
            this.errorMessages = "Description is required";
            return false;
        }

        if (!this.dataService.request.startdate) {
            this.isError = true;
            this.errorMessages = "Start date is required";
            return false;
        }

        if (!this.dataService.request.startdate) {
            this.isError = true;
            this.errorMessages = "End date is required";
            return false;
        }

        this.calculateBusinessDays(this.dataService.request.startdate, this.dataService.request.enddate);
        this.isError = false;
        this.errorMessages = '';
        return true;

    }

    changeDate() {

        if (!this.dataService.request.enddate) {
            this.dataService.request.enddate = this.dataService.request.startdate;
        }

        // is this a partial date?
        //if (this.dataService.request.partial) {

        this.dataService.request.startdate = new Date(`${this.dataService.request.startdate.toString().slice(0,15)} ${this.dataService.request.starttime}`)
        this.dataService.request.enddate = new Date(`${this.dataService.request.enddate.toString().slice(0,15)} ${this.dataService.request.endtime}`)
        //}

        this.calculateBusinessDays(this.dataService.request.startdate, this.dataService.request.enddate);
    }

    calculateBusinessDays(tStartDate, tEndDate){
        // Validate input
        if (endDate < startDate) {
            return 0;
        }
        
        var startDate: any = new Date(tStartDate);
        var endDate:any = new Date(tEndDate);

        // Calculate days between dates
        var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
        startDate.setHours(0,0,0,1);  // Start just after midnight
        endDate.setHours(23,59,59,999);  // End just before midnight
        var diff = endDate - startDate;  // Milliseconds between datetime objects    
        var days = Math.ceil(diff / millisecondsPerDay);
        
        // Subtract two weekend days for every week in between
        var weeks = Math.floor(days / 7);
        days = days - (weeks * 2);
        
        // Handle special cases
        var startDay = startDate.getDay();
        var endDay = endDate.getDay();
        
        // Remove weekend not previously removed.   
        if (startDay - endDay > 1)         
            days = days - 2;      
        
        // Remove start day if span starts on Sunday but ends before Saturday
        if (startDay == 0 && endDay != 6) {
            days = days - 1;  
        }
        
        // Remove end day if span ends on Saturday but starts after Sunday
        if (endDay == 6 && startDay != 0) {
            days = days - 1;
        }

        // if 1 day and partial
        if (days === 1 && this.dataService.request.partial) {
            var timeStart = new Date("01/01/2007 " + this.dataService.request.starttime).getHours();
            var timeEnd = new Date("01/01/2007 " + this.dataService.request.endtime).getHours();

            var hourDiff = timeEnd - timeStart;
            days = Math.min(hourDiff/8,1);
        }

        
        this.dataService.request.days = days;

        var hours = days * 8;


    }
}