import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { QueryOptions } from '@app/_models/query-opts';
import { PTOService } from '@app/_services';
import { map } from 'rxjs/operators';

@Injectable()
export class DataService {

    public reviews: any;
    public reviews2: any;

    public departments: any;
    public specialties: any;

    public allusers: any;
    userData: any;
    //public evaluators: any;


    public itemslist: any;
    public vendorslist: any;
    public noteslist: any;
    public item: any;
    public selectedVendor: any;
    public selecteditem: any;
    public selecteditemid: any;
    public selecteditemdescription: any;
    public startDate: any;
    public endDate: any;
    public showSimpleDatePicker: boolean;
    public selectedDates: { startDate: Moment, endDate: Moment };
    public dialogRef: any;
    
    public employees: any;
   
    public year;
    public configYear;
    public currentYear = new Date().getFullYear();
    
    public confirmationText = 'Text';
    public confirmationTitle = 'Title';

    public holidays = [];

    public request = {
        requestor: 0,
        requestorname: '',
        pto: 0,
        ptoremaining: 0,
        starttime: '09:00 AM',
        endtime: '05:00 PM',
        partial: false,
        startdate: null,
        enddate: null,
        notes: null,
        makeupnotes: null,
        days: 0,
        nopto: 0,
        nopaid: 0,
        paidhours: 0,
        unpaidhours: 0,
        approverid: 0,
        approvalnotes: null
    };

    
    queryOptions: QueryOptions = new QueryOptions();

    
    public constructor(
        private ptoService: PTOService
    ) {
        
    }

    getHolidays() {
        // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
        debugger;
        
        this.holidays = [];

        const currentYear = this.currentYear; //new Date().getFullYear();
        var minDate = `${this.currentYear}-01-01`;
        var maxDate = `${this.currentYear+1}-12-31`;

        // get all holidays for the current year
        this.ptoService.getBlocked(minDate, maxDate).pipe(
            map(holidays => {
                //this.users = users;
                debugger;
                this.holidays.push(holidays);


            }),
        ).subscribe();
        
    }

    public resetRequest() {
        this.request = {
            requestor: 0,
            requestorname: '',
            pto: 0,
            ptoremaining: 0,
            starttime: '09:00 AM',
            endtime: '05:00 PM',
            partial: false,
            startdate: null,
            enddate: null,
            notes: null,
            makeupnotes: null,
            days: 0,
            nopto: 0,
            nopaid: 0,
            paidhours: 0,
            unpaidhours: 0,
            approverid: 0,
            approvalnotes: null
        };
    }



}