<!--------------------------- EDIT REQUEST ---------------------------->
<div *ngIf="!adding" >
  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-11">
          <h3>PTO Request</h3>
      </div>
      <div class="col-1">
        <i class='fas fa-window-close' style='font-size:24px;color:gray; float: right;' (click)="close()"></i>
      </div>
    </div>

    <div class="row" >
      <div class="col-2">
        Requestor:
      </div>
      <div class="col-10">
          {{dataService.request.requestorname}}
      </div>
    </div>

    <div class="row" >
      <div class="col-2">
        Date:
      </div>
      <div class="col-10">
          {{dataService.request.starttime | date: 'short' }} - {{dataService.request.endtime | date: 'short' }}
      </div>
    </div>

    <div class="row" >
      <div class="col-2">
        Status:
      </div>
      <div class="col-10">
          {{dataService.request.status }}
      </div>
    </div>

    <div class="row" >
      <div class="col-2">
        Approver:
      </div>
      <div class="col-10">
          {{dataService.request.approver }}
      </div>
    </div>

    <div class="row" *ngIf="data.fullaccess">
      <div class="col-12">
        <hr>
      </div>
    </div>

    <div class="row" *ngIf="dataService.request.nopto && data.fullaccess">
      <div class="col-4">
        How will you make up the time?:
      </div>
      <div class="col-8">
          {{dataService.request.makeupnotes }}
      </div>
    </div>

    <div class="row" *ngIf="data.fullaccess">
      <div class="col-4">
        Notes:
      </div>
      <div class="col-8">
          {{dataService.request.notes}}
      </div>
    </div>

    <div class="row" *ngIf="data.fullaccess">
      <div class="col-4">
        Approver Notes:
      </div>
      <div class="col-8">
          {{dataService.request.approvalnotes}}
      </div>
    </div>    

    <div class="row" *ngIf="data.fullaccess">
      <div class="col-12">
        <hr>
      </div>
    </div>

    <div class="row w3-margin-bottom" *ngIf="data.fullaccess">
      <div class="col-12">
        <b>Hours Breakdown</b>
      </div>
    </div>


    <div class="row" *ngIf="data.fullaccess">
      <div class="col-4">
        Paid Hours:
      </div>
      <div class="col-8">
          {{dataService.request.paidhours }} <span *ngIf="dataService.request.status==='refunded'" style='color: red; padding-left: 10px'> (Refunded: {{dataService.request.paidhours}})</span>
      </div>
    </div>

    <div class="row w3-margin-bottom" *ngIf="data.fullaccess">
      <div class="col-4">
        Unpaid Hours:
      </div>
      <div class="col-8">
          {{dataService.request.unpaidhours }}
      </div>
    </div>

    <div class="row" *ngIf="dataService.request.nopto && data.fullaccess">
      <div class="col-2">
        Don't use PTO
      </div>
    </div>
    <!--
    <div class="row w3-margin-bottom" >
      <div class="col-12">
        <b>PTO Breakdown for Requestor</b>
      </div>
    </div>


    <div class="row" >
      <div class="col-4">
        PTO Hours Used:
      </div>
      <div class="col-8">
        {{dataService.request.pto}} {{ptoafter}}
      </div>
    </div>

    <div class="row" >
      <div class="col-4">
        PTO Hours Remaining:
      </div>
      <div class="col-8">
        {{dataService.request.ptoremaining}} {{ptoremainingafter}}
      </div>
    </div>
-->

    <div class="row spacer-t-10" *ngIf="data.fullaccess && (dataService.request.status==='new' || dataService.request.status==='approved') && (dataService.request.requestor != account.user.id || data.isSA)">
      <label>Approver Notes</label>
    </div>
    <div class="row" *ngIf="data.fullaccess && (dataService.request.status==='new'  || dataService.request.status==='approved') && (dataService.request.requestor != account.user.id || data.isSA)">          
      <mat-form-field appearance="fill">
        <textarea matInput [(ngModel)]="dataService.request.approvalnotes"></textarea>
      </mat-form-field>
    </div>
                          
    <div class="w3-container w3-row-padding w3-margin-top" *ngIf="data.fullaccess">
      <button class="w3-btn w3-blue-grey w3-margin-top w3-margin-right" *ngIf="dataService.request.status==='new' && (dataService.request.requestor != account.user.id || data.isSA)" (click)="approve()">Approve</button>
      <button class="w3-btn w3-grey w3-margin-top" *ngIf="dataService.request.status==='new' && (dataService.request.requestor != account.user.id || data.isSA)" (click)="deny()">Deny</button>     
      <button class="w3-btn w3-red w3-margin-top" *ngIf="dataService.request.status==='approved' && (dataService.request.requestor != account.user.id || data.isSA)" (click)="refund()">Refund</button>           
    </div>

<!--        <p>Request: {{dataService.request | json}}</p>

        <p>Request: {{account.userinfo | json}}</p>
        <p style="color: gray">Start Date: {{dataService.request.startdate}}</p>
        <p style="color: gray">End Date: {{dataService.request.enddate}}</p>
        <p style="color: gray">Days: {{dataService.request.days}}</p>
        <p style="color: gray">Paid Hours: {{dataService.request.paidhours}}</p>
        <p style="color: gray">Unpaid Hours: {{dataService.request.unpaidhours}}</p>-->
  </mat-dialog-content>
</div>


<!--------------------------- NEW REQUEST ---------------------------->


<div *ngIf="adding" >
      <mat-dialog-content class="mat-typography">
        <div class="row">
          <div class="col-11">
              <h3>PTO Request</h3>
          </div>
          <div class="col-1">
            <i class='fas fa-window-close' style='font-size:24px;color:gray; float: right;' (click)="close()"></i>
          </div>
        </div>
        <!--<div class="row">
          <label>Requestor {{dataService.request.requestor}}</label>
        </div>-->
        <div class="row" *ngIf="data.isSA || data.isManager">
          <div class="col-6">
            <mat-form-field appearance="fill" readonly >
              <mat-select id="requestor" [(ngModel)]="dataService.request.requestor" (selectionChange)="updateRequestor()">
                <mat-option [value]="account.user.id">{{ account.user.lastname }}, {{ account.user.firstname }}</mat-option>
                <mat-option #matOption *ngFor="let user of users" [value]="user.id">{{ user.lastname }}, {{ user.firstname }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            PTO Used: <br>
            PTO Remaining:<br>
            Paid/Unpaid:
          </div>
          <div class="col-2">
            {{dataService.request.pto}} {{ptoafter}}<br>
            {{dataService.request.ptoremaining}} {{ptoremainingafter}} <br>
            {{dataService.request.paidhours}}/{{dataService.request.unpaidhours}} 
          </div>
        </div>
        <div class="row" *ngIf="!data.isSA && !data.isManager">
          <div class="col-8">
            <mat-form-field appearance="fill">
              <input matInput disabled value="{{dataService.request.requestorname}}">
            </mat-form-field>
          </div>
          <div class="col-2">
            PTO Used: <br>
            PTO Remaining:<br>
            Paid/Unpaid Hours:
          </div>
          <div class="col-2">
            {{dataService.request.pto}} {{ptoafter}}<br>
            {{dataService.request.ptoremaining}} {{ptoremainingafter}} <br>
            {{dataService.request.paidhours}}/{{dataService.request.unpaidhours}} 
          </div>


        </div>        
        <div class="row">
          <label>Date range</label>
        </div>
        <div class="row">          
          <div class="col-12">
            <mat-form-field appearance="fill" class="example-form-field" style="display: contents" >
              <mat-date-range-input [rangePicker]="rangePicker" (change)="changeDate()" [dateFilter]="myFilter">
                <input matStartDate [min]="minDate" [max]="maxDate" [(ngModel)]="dataService.request.startdate" placeholder="Start date">
                <input matEndDate [min]="minDate" [max]="maxDate" [(ngModel)]="dataService.request.enddate" placeholder="End date">
              </mat-date-range-input>
              <!--<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>-->
              <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
              <mat-date-range-picker [dateClass]="dateClass" #rangePicker  >
                <mat-date-range-picker-actions>
                  <button mat-button matDateRangePickerCancel>Cancel</button>
                  <button mat-raised-button color="primary" matDateRangePickerApply (click)="changeDate()">Apply</button>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">  
          <div class="col-4">
            <mat-checkbox  [(ngModel)]="dataService.request.partial" *ngIf="dataService.request.days <= 1 && dataService.request.days > 0" (change)="changeDate()"> Partial day?</mat-checkbox> <br>
            <mat-checkbox  [(ngModel)]="dataService.request.nopto" (change)="changeDate()">Don't charge PTO?</mat-checkbox>
          </div>
          <div class="col-3" *ngIf="dataService.request.partial">
              <mat-form-field appearance="fill">
                <mat-label>Start Time</mat-label>
                <mat-select [(ngModel)]="dataService.request.starttime" (selectionChange)="changeDate()">
                  <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-1"></div>
            <div class="col-3" *ngIf="dataService.request.partial">
              <mat-form-field appearance="fill">
                <mat-label>End Time</mat-label>
                <mat-select [(ngModel)]="dataService.request.endtime" (selectionChange)="changeDate()">
                  <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

        </div>

        <div class="row" *ngIf="dataService.request.nopto">   
          
        </div>  
        <div class="row spacer-t-10" *ngIf="dataService.request.nopto && dataService.request.unpaidhours <= 2">
          <label>How do you plan to make up the time? (2 hours max)</label>
        </div>
        <div class="row" *ngIf="dataService.request.nopto && dataService.request.unpaidhours <= 2">          
          <mat-form-field appearance="fill">
            <textarea matInput [(ngModel)]="dataService.request.makeupnotes"></textarea>
          </mat-form-field>
        </div>
        <div class="row">   
          
        </div>  
        <div class="row spacer-t-10">
          <label>Notes</label>
        </div>
        <div class="row">          
          <mat-form-field appearance="fill">
            <textarea matInput [(ngModel)]="dataService.request.notes"></textarea>
          </mat-form-field>
        </div>
     
        <div class="row">          
          <mat-error *ngIf="isError">{{errorMessages}}</mat-error>
        </div>

                              
        <div class="w3-container w3-row-padding w3-margin-top">
          <button class="w3-btn w3-blue-grey w3-margin-top w3-margin-right" (click)="submit()">Submit</button>
          <button class="w3-btn w3-grey w3-margin-top" (click)="close()">Cancel</button>     
        </div>

        <!--<p>Request: {{dataService.request | json}}</p>-->
<!--
        <p>Request: {{account.userinfo | json}}</p>
        <p style="color: gray">Start Date: {{dataService.request.startdate}}</p>
        <p style="color: gray">End Date: {{dataService.request.enddate}}</p>
        <p style="color: gray">Days: {{dataService.request.days}}</p>
        <p style="color: gray">Paid Hours: {{dataService.request.paidhours}}</p>
        <p style="color: gray">Unpaid Hours: {{dataService.request.unpaidhours}}</p>-->
      </mat-dialog-content>

</div>



