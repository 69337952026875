import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize, first, map } from 'rxjs/operators';
import { NamelistComponent } from '@app/_components/namelist/namelist.component';
import { ManagerlistComponent } from '@app/_components/managerlist/managerlist.component';
import { AccountService, AlertService } from '@app/_services';
import { ConfigService } from '../../_services/config.service';
import { DataService } from '@app/_services';
import { MustMatch } from '@app/_helpers';
import { MatDialog } from '@angular/material/dialog';
import { fakeAsync } from '@angular/core/testing';

@Component({ templateUrl: 'add-edit.component.html' })
export class AddEditComponent implements OnInit {
    form: UntypedFormGroup;
    id: string;
    isAddMode: boolean;
    loading = false;
    submitted = false;
    item: any = {
        job: '',
        description: '',
        duedate: '',
        unitprice: 0,
        uom: 'ea',
        quantity: 0,
        amount: 0
  
    };
    olditem;
    isAdmin: boolean;
    isManager: boolean;
    account = this.accountService.accountValue;

    constructor(
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService,
        public dataService: DataService,
        private configService: ConfigService,
    ) { }

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;

        this.isAdmin = this.account.user.role === 'admin';
        this.isManager = this.account.user.role === 'manager';


        this.form = this.formBuilder.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['@', [Validators.required, Validators.email]],
            role: ['', Validators.required],
            manager: ['', Validators.required],
            managername: ['', Validators.required],
            password: [' ', [Validators.minLength(6), this.isAddMode ? Validators.required : Validators.nullValidator]],
            confirmPassword: ['']
        });

        if (!this.isAddMode) {
            this.accountService.getById(this.id)
                .pipe(first())
                .subscribe(x => this.form.patchValue(x));



                //this.loadEvaluators(this.id);
                //this.loadEvaluations(this.id);
        }

        


    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }
    
    onSubmit() {
        debugger;
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

    

        this.loading = true;
        if (this.isAddMode) {
            this.createAccount();
        } else {
            this.updateAccount();
        }
    }

    saveBasic() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createAccount();
        } else {
            this.updateAccount();
        }

    }

    private createAccount() {
        var obj = this.form.value;
        delete obj.confirmPassword;
        delete obj.managername;
        obj.username = obj.email;
        this.accountService.create(obj)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Account created successfully', { keepAfterRouteChange: true });
                    this.router.navigate(['../'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    private updateAccount() {
        var obj = this.form.value;

        if (obj.password === '') {
            delete obj.password;
        }

        delete obj.confirmPassword;
        delete obj.managername;

        this.accountService.update(this.id, obj)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.router.navigate(['../../'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }



      chooseManager() {
          
        this.dataService.dialogRef = this.dialog.open(ManagerlistComponent, {
            height: '80%',
            width: '90%',
        });
        this.dataService.dialogRef.afterClosed().subscribe(result => {
            this.f.manager.setValue(result.value.id);
            this.f.managername.setValue(result.value.firstname + " " + result.value.lastname);
        });
        return false;
      }


      delay(ms: number)
      {
          return new Promise(resolve => setTimeout(resolve, ms));
      }

    

       

}